<template>
  <div class="container pr-2 my-4">
    <div class="my-6 flex justify-end">
      <router-link to="/gerenciador/glossario/adicionar" class="button"
        >Novo verbete</router-link
      >
    </div>

    <ul class="list w-full bg-white">
      <li v-for="entry in orderedGlossary" :key="entry._id">
        <div>
          {{ entry.order }} <strong class="pl-6">{{ entry.titleAdmin }}</strong>
        </div>
        <div class="flex">
          <router-link
            :to="`/gerenciador/glossario/${entry._id}`"
            class="button mr-2 green"
          >
            Editar
          </router-link>
          <div class="button red" @click.prevent="remove(entry._id)">
            Excluir
          </div>
        </div>
      </li>
      <li v-if="entries.length == 0">Nenhum verbete encontrada</li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "GlossaryList",
  components: {},
  filters: {},
  props: {},
  data: function() {
    return {};
  },
  computed: {
    ...mapState({
      entries: state => state.Glossary.list
    }),
    // ...mapGetters({
    //   item: "namespace/item"
    // })
    orderedGlossary() {
      if (this.entries.length > 0) {
        return [...this.entries].sort((a, b) =>
          a.titleAdmin.localeCompare(b.titleAdmin, "pt", {
            ignorePunctuation: true
          })
        );
      } else {
        return [];
      }
    }
  },
  watch: {},
  async created() {
    await this.$store.dispatch("Glossary/list");
  },
  async mounted() {},
  methods: {
    async remove(entryId) {
      const shouldRemove = confirm("Deseja mesmo apagar esse verbete?");
      if (shouldRemove == true) {
        await this.$store.dispatch("Glossary/remove", entryId);
        await this.$store.dispatch("Glossary/list");
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
